import { post } from '@/utils/request';

export const fetchHomeData = async data => {
  return await post('/appdealer/api/channelDistributionMap/getIndexNum', data)
}

export const fetchOrderData = async data => {
  return await post('/appdealer/api/report/indexOrderOverview', data)
}

export const fetchBalance = async data => {
  return await post('/appdealer/api/report/dealerBalance', data)
}

export const fetchFiList = async data => {
  return await post('/appdealer/api/report/dealerFiList', data)
}

export const fetchTeamData = async data => {
  return await post('/appdealer/api/saleman/getTotalData', data)
}

export const fetchTeamList = async data => {
  return await post('/appdealer/api/saleman/list', data)
}

export const fetchMaterial = async data => {
  return await post('/appdealer/api/material/getMaterialList', data)
}

export const fetchChartData = async data => {
  return await post('/api/report/dealerSales', data);
}