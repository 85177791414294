<template>
  <div class="home-page">

    <!-- 顶部导航栏 -->
    <div class="header">
      <div class="nick">{{nick}}，您好</div>
      <router-link to="/notification">
        <img class="notification" src="../../assets/images/home/notification.png" />
      </router-link>
    </div>

    <!-- 任务总览 -->
    <div class="task-dashboard">
      <router-link to="/task/listStatus">
        <div class="card">
          <div class="title">我发布的任务</div>
          <div class="num">{{homeData.giveTask || 0}}</div>
        </div>
      </router-link>
      <router-link to="/task/listStatus?type=1">
        <div class="card">
          <div class="title">我执行的任务</div>
          <div class="num">{{homeData.getTask || 0}}</div>
        </div>
      </router-link>
    </div>
    <router-link to="/task/publishTask">
      <Button class="task-button">下达任务</Button>
    </router-link>

    <!-- 导航入口 -->
    <div class="nav">
      <div class="nav-row">
        <router-link to="/terminalOutlets">
          <NavItem icon="zdwd" label="终端网点" />
        </router-link>
        <router-link to="/bigGrowers">
          <NavItem icon="zzdh" label="种植大户" />
        </router-link>
        <router-link to="/potentialCustomers">
          <NavItem icon="qzkh" label="潜在客户" />
        </router-link>
        <router-link to="/emptyArea">
          <NavItem icon="kbqy" label="空白区域" />
        </router-link>
      </div>
      <div class="nav-row">
        <router-link to="/agriculturalTechnology">
          <NavItem icon="njh" label="农技会" />
        </router-link>
        <router-link to="/demonstrationField">
          <NavItem icon="sft" label="示范田" />
        </router-link>
        <router-link to="/bill/manage">
          <NavItem icon="zkgl" label="账款管理" />
        </router-link>
        <router-link to="/message/batch">
          <NavItem icon="dxqf" label="短信群发" />
        </router-link>
      </div>
    </div>

    <!-- 地图 -->
    <router-link to="/map" class="map-mask">
      <div class="map-container" id="mapContainer"></div>
    </router-link>

    <!-- 数据看板 -->
    <Dashboard v-if="role !== '属地业务'"/>

    <!-- 我的团队 -->
    <Team v-if="role !== '属地业务'"/>

    <Agro/>

    <!-- tabbar -->
    <Tabbar />
  </div>
</template>

<script>
import * as user from '@/utils/user';
import { fetchHomeData } from '@/api/home';
import Team from './components/team';
import Agro from './components/agro';
import NavItem from './components/navItem';
import Dashboard from './components/dashboard';
import { initMap } from '../../utils/map';
import Tabbar from '../../components/common/tabbar/index';
export default {
  components: {
    Team,
    Tabbar,
    NavItem,
    Dashboard,
    Agro
  },
  data() {
    return {
      nick: user.getNickName(),
      homeData: {},
      role: user.getUserType(),
    }
  },
  async activated() {
    const res = await fetchHomeData();
    this.homeData = res.data;
  },
  async mounted() {
    initMap('mapContainer', { currentLocation: true });
  },
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #FAFBFC;
  z-index: 10;
  padding-bottom: 92px;
  box-sizing: border-box;

  &:before {
    content: ' ';
    position: absolute;
    width: 750px;
    height: 750px;
    border-radius: 44%;
    background: #CD011F;
    top: -623px;
    left: -187.5px;
    z-index: -1;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 24px 24px 25px;
    .nick {
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      color: #FFFFFF;
    }
    .notification {
      width: 15px;
      height: 16px;
    }
  }

  .task-dashboard {
    width: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .card {
      width: 170px;
      height: 80px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      padding: 11px 13px 6px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title {
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
        color: #818181;
      }
      .num {
        font-size: 23px;
        font-weight: 600;
        line-height: 33px;
        color: #2C2C2C;
      }
    }
  }
  .task-button {
    width: 351px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    color: #FFFFFF;
    background: #CD011F;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    border: none;
    margin-top: 12px;
  }

  .nav {
    padding: 2px 29px 14px;
    margin: 12px auto 0;
    width: 351px;
    height: 160px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    box-sizing: border-box;
    .nav-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .map-container {
    width: 351px;
    height: 160px;
    margin: 12px auto 0;
    background-color: #ddd;
    border-radius: 10px;
  }
  .map-mask {
    position: relative;
    display: block;
    &:after {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
  }
}
</style>
