<template>
  <div class="team">
    <div class="header">
      <span class="title">我的团队</span>
      <router-link to="/shidanliBusiness">
        <div class="team-button">
          <img class="team-icon" src="../../../assets/images/home/team.png" />
          <span class="team-label">团队管理</span>
        </div>
      </router-link>
    </div>

    <div class="dashboard">
      <div class="item">
        <div class="num">{{teamNum}}</div>
        <div class="label">团队成员</div>
      </div>
      <div class="item">
        <div class="num">{{taskNum}}</div>
        <div class="label">总任务数</div>
      </div>
      <div class="item">
        <div class="num">{{tasking}}</div>
        <div class="label">进行中</div>
      </div>
    </div>

    <Tabs class="tabs">
      <Tab title="空闲中成员">
        <div class="member-container">
          <div class="member" v-for="(member, idx) in noTaskMembers.slice(0,3)" :key="member.userId" @click="navigateDetailPage(member.userId)">
            <div class="avatar" :style="{backgroundColor: colorList[idx % 4]}">
              {{member.nickName.slice(0, 1)}}
            </div>
            <div class="info">
              <div class="nick">{{member.nickName}}</div>
              <div class="phone">{{member.phonenumber}}</div>
            </div>
            <div class="button-container">
              <Button class="button" size="mini" @click.stop="handleTask(member)">下达任务</Button>
              <Button class="button" size="mini-plain" @click.stop="handleVisit(member.userId)">拜访记录</Button>
            </div>
          </div>
          <InfiniteLoading 
            :distance="200" 
            spinner="waveDots"
            @infinite="getTeamMemberNoTask" 
          >
            <div slot="no-more" class="infinite-loading-no-more">
              <!-- 暂无更多 -->
            </div>
          </InfiniteLoading>
        </div>
      </Tab>
      <Tab title="任务中成员">
        <div class="member-container">
          <div class="member" v-for="(member, idx) in taskMembers.slice(0,3)" :key="member.userId" @click="navigateDetailPage(member.userId)">
            <div class="avatar" :style="{backgroundColor: colorList[idx % 4]}">
              {{member.nickName.slice(0, 1)}}
            </div>
            <div class="info">
              <div class="nick">{{member.nickName}}</div>
              <div class="phone">{{member.phonenumber}}</div>
            </div>
            <div class="button-container">
              <Button class="button" size="mini" @click="toMapTrace(member)">轨迹查询</Button>
              <router-link :to="`/visit/list?userId=${member.userId}`">
                <Button class="button" size="mini-plain">拜访记录</Button>
              </router-link>
            </div>
          </div>
          <InfiniteLoading 
            :distance="200" 
            spinner="waveDots"
            @infinite="getTeamMemberUnderTask" 
          >
            <div slot="no-more" class="infinite-loading-no-more">
              <!-- 暂无更多 -->
            </div>
          </InfiniteLoading>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { fetchTeamData, fetchTeamList } from '@/api/home';
import InfiniteLoading from "vue-infinite-loading";
import Button from '../../../components/common/button/CustomButton';
import { Tabs, Tab } from 'vant';
export default {
  components: {
    Tab,
    Tabs,
    Button,
    InfiniteLoading
  },
  mounted() {
    this.getTeamData();
  },
  data() {
    return {
      teamNum: 0,
      taskNum: 0,
      tasking: 0,
      colorList: ['#FF6A36', '#03C714', '#335AFF', '#FF4A9C'],
      noTaskPageNum: 1,
      underTaskPageNum: 1,
      pageSize: 10,
      noTaskMembers: [],
      taskMembers: [],
      isLoadingNoTask: false,
    }
  },
  methods: {
    handleTask(item) {
      this.$router.push({ name: "TaskPublishTask", params: { sendUser: item } });
    },
    handleVisit(userId) {
      this.$router.push({ path: `/visit/list?userId=${userId}` });
    },
    async getTeamData() {
      const res = await fetchTeamData();
      this.teamNum = res.data.TOTALNUBMBER;
      this.taskNum = res.data.TOTALTASK;
      this.tasking = res.data.UNDERWAY;
    },
    async getTeamMemberNoTask($state) {
      if (this.isLoadingNoTask) {
        return;
      }
      this.isLoadingNoTask = true;
      try {
        const res = await fetchTeamList({
          takeStatus: 1,
          'page.pageNo': this.noTaskPageNum,
          'page.pageSize': this.pageSize,
        });
        this.isLoadingNoTask = false;
        this.noTaskPageNum++;
        this.noTaskMembers = this.noTaskMembers.concat(res.data);
        if (res.page.hasNextPage) {
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      } catch (e) {
        this.isLoadingNoTask = false;
      }
    },
    async getTeamMemberUnderTask($state) {
      const res = await fetchTeamList({
        takeStatus: 0,
        'page.pageNo': this.underTaskPageNum,
        'page.pageSize': this.pageSize,
      });
      this.underTaskPageNum++;
      this.taskMembers = this.taskMembers.concat(res.data);
      if (res.page.hasNextPage) {
        $state.loaded();
      } else {
        $state.loaded();
        $state.complete();
      }
    },
    toMapTrace(item) {
      this.$router.push({ name: 'MapTrace', params: { user: item} });
    },
    navigateDetailPage(id) {
      this.$router.push({
        name: "ShidanliBusinessMemberDetail",
        params: { id },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.team {
  width: 351px;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 10px;
  margin: 12px auto 0;
  box-sizing: border-box;
  padding: 12px 12px 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
    }
    .team-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .team-icon {
        width: 10px;
        height: 12px;
        margin-right: 2px;
      }
      .team-label {
        font-size: 12px;
        font-weight: 400; 
        line-height: 17px;
        color: #CD001F;
      }
    }
  }
  .dashboard {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-top: 13px;
    width: 326px;
    height: 70px; 
    padding: 0 30px;
    justify-content: space-between;
    box-sizing: border-box;
    .num {
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      color: #2C2C2C;
    }
    .label {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #818181;
    }
  }
  .tabs {
    margin-top: 21px;
  }
  .member-container {
    padding-top: 10px;
    .member {
      border-top: 1px solid #EAEAEA;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }
      .info {
        margin-left: 8px;
        text-align: left;
        flex: 1;
        .nick {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #2C2C2C;
        }
        .phone {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #006EFB;
        }
      }
      .button-container {
        width: 162px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button {
          display: block;
          width: 75px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>