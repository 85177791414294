<!-- 往来明细 -->
<template>
  <div class="account-detail">
    <div class="content">
      <div class="item" v-for="item in data" :key="item.id">
        <div class="header">
          <div class="num">{{parseFloat(item.smbtr) || parseFloat(item.hmbtr) || 0}}</div>
          <div class="type">{{parseFloat(item.smbtr) ? '收款' : '来款'}}/元</div>
        </div>
        <div class="footer">
          <div class="summary">摘要：{{item.sgtxt}}</div>
          <div class="datetime">{{item.budat}}</div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <router-link to="/info/contactsInfo">
        <Button class="button" size="mini-plain">查看全部</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/common/button/CustomButton';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
.account-detail {
  .content {
    width: 326px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin-top: 10px;
    padding-bottom: 12px;
    .item {
      padding: 8px 10px 5px;
      box-sizing: border-box;
      border-bottom: 1px solid #EAEAEA;
      .header {
        display: flex;
        align-items: center;
        .num {
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
          color: #2C2C2C;
        }
        .type {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #818181;
          margin-left: 7px;
        }
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #CECECE;
      }
    }
  }
  .button {
    width: 260px;
    &-container {
      width: 100%;
      display: flex;
      margin-top: 8px;
      padding-bottom: 4px;
      justify-content: center;
    }
  }
}
</style>