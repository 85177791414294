<template>
  <div class="knowledge">
    <div class="content">
      <div class="item" v-for="item in data" :key="item.id" @click="navigateDetailPage(item)">
        <div class="cover" :style="{backgroundImage: `url(${item.largerUrl})`}"></div>
        <div class="info">
          <div class="title">{{item.materialName}}</div>
          <div class="desc">{{item.materialDesc || ''}}</div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <router-link to="/shidanliBusiness/knowledge">
        <Button class="button" size="mini-plain">查看全部</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/common/button/CustomButton';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    Button,
  },
  methods: {
    navigateDetailPage(item) {
      window.localStorage.setItem("KnowledgeDetail", JSON.stringify(item));
      this.$router.push({
        name: "ShidanliBusinessKnowledgeDetail",
        params: { id: item.id },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.knowledge {
  .content {
    width: 326px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin-top: 10px;
    padding-bottom: 12px;
    .item {
      height: 64px;
      display: flex;
      align-items: center;
      padding: 9px 8px;
      box-sizing: border-box;
      border-bottom: 1px solid #EAEAEA;
      .cover {
        width: 49px;
        height: 47px;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        border-radius: 5px;
        background-size: contain;
      }
      .info {
        margin-left: 10px;
        height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #2C2C2C;
        }
        .desc {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #CECECE;
        }
      }
    }
  }
  .button {
    width: 260px;
    &-container {
      width: 100%;
      display: flex;
      margin-top: 8px;
      padding-bottom: 4px;
      justify-content: center;
    }
  }
}
</style>