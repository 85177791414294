<template>
  <div class="dashboard">
    <div class="title">我的史丹利</div>
    <Tabs swipeable swipe-threshold="3">
      <Tab title="订单概况">
        <Order :data="orderData" />
      </Tab>
      <Tab title="余额查询">
        <Balance @refresh="getBalance" :data="balanceData" />
      </Tab>
      <Tab title="往来明细">
        <AccountDetail :data="fiList" />
      </Tab>
      <Tab title="产品知识">
        <Knowledge :data="materialList" />
      </Tab>
      <Tab title="报表统计">
        <Chart :data="chartData" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Order from './order';
import Chart from './chart';
import Balance from './balance';
import Knowledge from './knowledge';
import AccountDetail from './accountDetail';
import { fetchBalance, fetchFiList, fetchMaterial, fetchChartData, fetchOrderData } from '@/api/home';
import { Tabs, Tab } from 'vant';
export default {
  components: {
    Tab,
    Tabs,
    Chart,
    Order,
    Balance,
    Knowledge,
    AccountDetail,
  },
  data() {
    return {
      balanceData: {},
      fiList: [],
      materialList: [],
      chartData: [],
      orderData: {},
    }
  },
  mounted() {
    this.getBalance({});
    this.getFiList();
    this.getMaterial();
    this.getChartData();
    this.getOrderData();
  },
  methods: {
    async getBalance({ date } = {}) {
      const res = await fetchBalance({ endDate: date });
      this.balanceData = res.data;
    },
    async getFiList() {
      const res = await fetchFiList({
        endDate: dayjs(new Date()).format('YYYYMMDD'),
        beginDate: `${dayjs(new Date()).format('YYYY')}0101`,
      });
      this.fiList = res.data.slice(0, 3);
    },
    async getMaterial() {
      const res = await fetchMaterial({});
      this.materialList = res.data.slice(0, 3);
    },
    async getChartData() {
      const res = await fetchChartData();
      this.chartData = res.data;
    },
    async getOrderData() {
      const res = await fetchOrderData();
      this.orderData = res.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  width: 351px;
  margin: 12px auto 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 10px;
  padding: 12px 12px 9px;
  box-sizing: border-box;
  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: #2C2C2C;
    padding-bottom: 10px;
    text-align: left;
  }
}
</style>