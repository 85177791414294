<template>
  <div class="chart-container">
    <div id="myChart" class="chart"></div>
    <!-- <router-link to="/dataMonitor">
      <Button class="button">报表中心</Button>
    </router-link> -->
  </div>
</template>

<script>
// 引入基本模板
const echarts = require('echarts')
// 引入柱状图组件
require('echarts/lib/chart/pie')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')

// import Button from '../../../components/common/button/CustomButton';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  // components: {
  //   Button,
  // },
  mounted() {
    this.drawLine();
  },
  watch: {
    data() {
      this.drawLine();
    }
  },
  methods: {
    drawLine(){
      if (!this.data) {
        return false;
      }
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'));
      const rowData = {};
      this.data.forEach(d => {
        if (!rowData[d.materialName]) {
          rowData[d.materialName] = parseFloat(d.salesNumber);
        } else {
          rowData[d.materialName] += parseFloat(d.salesNumber);
        }
      });
      // 绘制图表
      myChart.setOption({
          color: ['#F56C6C', '#E5A23C'],
          title: { 
            subtext: '销量统计',
            textStyle: {
              fontSize: '12px',
            },
            x: 'center'
          },
          tooltip: {},
          series: [{
            name: '销量统计',
            type: 'pie',
            radius: '70%',
            data: Object.keys(rowData).map(key => {
              return {
                value: rowData[key],
                name: key,
              }
            })
          }]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 300px;
  &-container {
    padding-bottom: 12px;

    .button {
      width: 200px;
    }
  }
}
</style>