<template>

  <div class="dashboard">
    <div class="header">
      <span class="title">农化知识</span>
      <router-link to="/agro/detail">
        <div class="team-button">
          <span class="team-label">查看全部</span>
        </div>
      </router-link>
    </div>
    <Tabs swipeable swipe-threshold="1" @change="tabClick">
      <Tab
              v-for="(item) in data"
              :key="item.id"
              :title="item.label"
              :name="item.ancestors"
      >
        <div
                v-for="(item) in itemList"
                :key="item.id"
                class="item-wrap"
                @click="handleClick(item)"
        >

          <img :src="item.imgUrl" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="catename"><tag class="van-tag van-tag--round van-tag--warning">{{ item.cateName }}</tag></div>
          <div class="actions">
            <span class="time">{{ item.createTime }}</span>

            <span class="scannum">
               <img class="team-icon" src="../../../assets/images/home/view.png" />{{ item.scanNum }}人阅读</span>
          </div>

        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
    import { treeselect,articleList } from '@/api/agro';
    import { Tabs, Tab, Tag } from 'vant';
    export default {
        components: {
            Tab,
            Tabs,
            Tag,
        },
        data() {
            return {
                data:[],
                itemList:[],
            }
        },
        mounted() {
            this.getTreeselect();

        },
        methods: {
            handleClick(link) {
                this.$router.push({
                    name: 'AgroInfo',
                    params: {
                        data: link
                    }
                })
            },
            async tabClick(name){
              this.articleList(name);
            },
            async getTreeselect() {
                const res = await treeselect({});
                this.data = res.data[0].children;
                if(this.data.length>0){
                    const ancestors = this.data[0].ancestors;
                    this.articleList(ancestors)
                }
            },
            async articleList(ancestors){
                const res = await articleList({
                    ancestors:ancestors,
                    "page.pageNo": 1,
                    "page.pageSize": 5,
                });
                this.itemList = res.data;
            }

        }
    }
</script>

<style lang="scss" scoped>
  .dashboard {
    width: 351px;
    margin: 12px auto 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    padding: 12px 12px 9px;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
      padding-bottom: 10px;
      text-align: left;
    }
    .item-wrap {
      margin-bottom: 12px;
      flex: 0 0 25%;
      padding-left: 5px;
      padding-top: 15px;
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
      }
      .catename{
        font-size: 12px;
        padding-left:20px;
        line-height: 14px;
        color: #2c2c2c;
        text-align: left;
      }
      .time{
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
        color: #2c2c2c;
      }
      .scannum{
        font-size: 12px;
        line-height: 14px;
        color: #2c2c2c;
      }
      img {
        width: 65px;
        height: 57px;
        float: left;
        object-fit: cover;
        margin-bottom: 6px;
        padding-right: 6px;
      }
    }
    .agro-label{
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        padding-bottom: 0;
        color: #2C2C2C;
      }
      .team-button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .team-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #CD001F;
        }
      }
    }
    .actions {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .team-icon {
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }
    }

  }
</style>
